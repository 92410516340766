import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TopDots from "../../static/images/about-blue-dots.svg"
import YellowDot from "../../static/images/large-yellow-dot.svg"
import PinkLine from "../../static/images/pink-line.svg"
import Img from 'gatsby-image';


const Experts = props => (
  <Layout>
    <Helmet>
      <title>Our experts | IBDmate</title>
    </Helmet>
    <div className="layout-about">
      <div className="yellow-bg">
        <div className="wrapper">
          <div className="content">
            <h1>Our experts</h1>
            <p>We’ve worked with lots of IBD experts so we can bring you trusted information about all aspects of inflammatory bowel disease.</p>
          </div>
          <div className="vectors">
            <img src={TopDots} id="blue-dots" alt="" />
            <img src={YellowDot} id="yellow-dot" alt="" />
            <img src={PinkLine} id="pink-line" alt="" />
          </div>
        </div>
      </div>
      <div className="white-bg">
        <div className="wrapper people">
          {props.data.experts.people.map(person => (
            <div className="person">
              <Img
                fluid={person.frontmatter.profileImage.childImageSharp.fluid}
                alt="{person.frontmatter.name}"
              />
              <p><strong>{person.frontmatter.name}</strong>
              <br />{person.frontmatter.title}
              <br />{person.frontmatter.hospital}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export default Experts

export const pageQuery = graphql`
  query {
    experts: allMarkdownRemark(
      filter:{
        fileAbsolutePath:{regex:"/(experts)/"}
      }
      sort:{
        order:ASC,
        fields: [frontmatter___name]
      }
    ){
    people: nodes {
        frontmatter {
          name
          title
          hospital
          profileImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
